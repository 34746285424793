@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css?family=Inter');

body {
  font-family: 'Inter', sans-serif;
}

@supports (font-variation-settings: normal) {
  body {
    font-family: 'Inter var', sans-serif;
  }
}

@font-face {
  font-family: "Inter";
  src: url("../public/assets/fonts/Inter-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Inter-bold";
  src: url("../public/assets/fonts/Inter-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Inter-extraBold";
  src: url("../public/assets/fonts/Inter-ExtraBold.ttf");
  font-display: swap;
}

* {
  font-family: 'Inter'
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}